var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.qs && !_vm.loading ? _c('div', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.rules')) + " ")]), _c('div', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('global.compliance_index.value_desc'))
    }
  }), _c('br'), _c('strong', [_vm._v(_vm._s(_vm.$t('global.compliance_index.score_desc')) + " : ")]), _c('br'), _c('br'), _c('List', {
    attrs: {
      "size": "small",
      "split": false
    }
  }, [_c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['A']
    }
  }, [_vm._v(" A ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[0] && _vm.thresoldValues[0].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['B']
    }
  }, [_vm._v(" B ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[1] && _vm.thresoldValues[1].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['C']
    }
  }, [_vm._v(" C ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[2] && _vm.thresoldValues[2].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['D']
    }
  }, [_vm._v(" D ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[3] && _vm.thresoldValues[3].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['E']
    }
  }, [_vm._v(" E ")]), _vm._v(_vm._s(_vm.$t('global.compliance_index.if_value_above')) + " "), _c('strong', [_vm._v("   " + _vm._s(_vm.thresoldValues[3] && _vm.thresoldValues[3].value))])], 1)], 1), _c('Divider'), _c('p', [_c('strong', [_vm._v("The " + _vm._s(_vm.$t('global.compliance_index.index')) + " " + _vm._s(_vm.$t('global.compliance_index.value_built_on')) + " ")])]), _c('br'), _c('List', {
    attrs: {
      "split": false,
      "size": "small"
    }
  }, [_c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C1a] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r1')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C1b] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r2')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C2a] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r3')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C2b] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r4')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C3] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r5')) + " ")])], 1), _c('Divider'), _c('p', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.nb')) + " "), _c('Tag', [_vm._v("1")])], 1), _c('br'), _c('p', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.value')) + " = ( "), _c('Tag', [_vm._v("2")]), _vm._v(" x C1a) + ( "), _c('Tag', [_vm._v("4")]), _vm._v(" x C1b) + ( "), _c('Tag', [_vm._v("3")]), _vm._v(" x C2a) + ( "), _c('Tag', [_vm._v("5")]), _vm._v(" x C2b) + ( "), _c('Tag', [_vm._v("1")]), _vm._v(" x C3)")], 1)], 1)])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }